import * as React from 'react';
import './styles.scss';
import {Icon, ICON_TYPES} from '../Icons';
import {useTranslationHook} from '@/hooks/useTranslationHook';

interface IContentBannerProps {
    content: {
        text?: string;
        useLogo?: boolean;
    }[];
}

export const ContentBanner: React.FC<IContentBannerProps> = ({ content }) => {

    const { translate } = useTranslationHook();

    return <div className='content-banner'>
        <h1>
            {content.map((item) => {
                return (item?.useLogo ? <Icon icon={ICON_TYPES.LOGO_TEXT}/> : (item.text ? translate(item.text) : ''));
            })}
        </h1>
    </div>;
};