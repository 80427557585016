export const externalLinks = {
    sjFacebook: 'https://www.facebook.com/swipejobs',
    sjInstagram: 'https://www.instagram.com/swipejobs',
    sjLinkedin: 'https://www.linkedin.com/company/swipejobs',
    psLinkedin:'https://www.linkedin.com/company/swipejobs-for-premier',
    swipejobsSupport: 'support@swipejobs.com',
    swipejobsPrivacy: 'privacy@swipejobs.com',
    swipejobsInfo: 'info@swipejobs.com',
    workerAppIos: 'http://swipe.jobs/worweb',
    workerAppAndroid: 'http://swipe.jobs/worweb',
    customerAppIos: 'https://apps.apple.com/app/swipejobs-business/id1495004685',
    customerAppAndroid: 'https://play.google.com/store/apps/details?id=exchange.ondemand.swipejobs.customer',
    swipejobsWebsite: 'https://www.swipejobs.com',
    customerDesktop: 'https://sj-customer.swipejobs.com/login',
    careersLink: 'https://swipejobs.breezy.hr/',
    contactFormUrl: 'https://formspree.io/mnqgavjz',
    sjDevUrlPrefix:'https://api.sj.preprod.sj-exchange.com/v3/',
    sjPreProdUrlPrefix: 'https://api.sj.preprod.sj-exchange.com/v3/',
    sjProdUrlPrefix: 'https://api.sj.prod.sj-exchange.com/v3/',
    sjSendSms: 'desktop-notifications/external/notification/sendToAddress',
    sjGuestToken: 'identity/sessions/guest',
    sjBranchesData: 'branch-view/public/branches',
    sjWorkerFaq: 'https://www.swipejobs.com/workers#faq',
    sjBusinessFaq: 'https://www.swipejobs.com/business#faq',
    sjContactUs: 'https://www.swipejobs.com/about#contact-us',
    sjPrivacy: 'https://www.swipejobs.com/privacy#top',
    premierHRCenter: 'https://webcenter.tempworks.com/PremierEmployees/Account/LogIn?ReturnUrl=%2fPremierEmployees',
    workerLandingDownload: 'https://landing.swipejobs.com/app-download',
    gsaSchedule:'/-/media/corestaff/gsa-schedule.pdf',
    jobsHomePage:'https://www.swipejobs.com/jobs',
    benefitsUrl:'https://benefits.thepremierpride.com/',
    webCenterLink:'https://webcenter.ontempworks.com/PremierEmployees/Account/LogIn',
    transparencyInCoverage: "https://sisconosurprise.com/organizations/202824401.html",
    applicantPreProdUrlPrefix: 'https://applicant-capture-pages.sj.preprod.sj-exchange.com/',
    applicantProdUrlPrefix: 'https://swipejobs.com/',
    googleMapsUrlPrefix: 'https://www.google.com/maps/dir/?api=1&destination=',
    presentationVideoUrl: 'https://fast.wistia.net/embed/iframe/kagnhej70z?videoFoam=true',
    businessPageVideoSectionUrl: 'https://fast.wistia.net/embed/iframe/mya9evxypk?videoFoam=true'
};
