import * as React from 'react';
import InfoTilesView from '@/controllers/InfoTilesView';
import ContactUsView from '@/controllers/ContactUsView';
import {TrackingCategories} from '@/models/trackingModel';
import {HeroBanner} from '@/components/HeroBanner';
import * as ABOUT from '@/data/pages/about';
import {useTrackingHook} from '@/hooks/useTrackingHook';
import {useTranslationHook} from '@/hooks/useTranslationHook';
import {ImageCol} from '@/components/ImageCol';
import './styles.scss';

export const About = () => {

    const {trackPage} = useTrackingHook();
    const {
        translate,
        translateObject
    } = useTranslationHook();

    React.useEffect(() => {
        trackPage(TrackingCategories.About);
    }, [trackPage]);

    const renderParagraphSection = (title: string, content: string) => {
        const contentArray: any = translateObject(content);
        return (
            <div className='mb-5'>
                <h3 className='content-col__title main-title'
                    style={{textAlign: 'center'}}>{translate(title)}
                </h3>
                {contentArray.map((item: string) => (
                    <p key={item} className='content-col__subtitle secondary-title text-justify'>{item}</p>
                ))}
            </div>
        )
    };

    return (
        <div id='top'
             className='about-page'>
            <HeroBanner {...ABOUT.HERO_BANNER} />
            <div className='animated-component active'>
                <div className='image-content-col image-content-col--reverse image-content-col--margin-bottom'>
                    <div className='image-content-col__inner'>
                        <div className='image-content-col__left' style={{width: '100%'}}>
                            <div className='content-col'>
                                <div className='content-col__body'>
                                    {renderParagraphSection('whoIsSwipeJobs', 'whoIsSwipeJobsDescription')}
                                    {renderParagraphSection('whoIsSwipeJobsPart2Title', 'whoIsSwipeJobsPart2Content')}
                                    {renderParagraphSection('whoIsSwipeJobsPart3Title', 'whoIsSwipeJobsPart3Content')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InfoTilesView
                title={translate('ourPlatform')}
                tileList={ABOUT.PLATFORM_LIST}
            />
            <div className='bottom-section'>
                <div className='bottom-section__logo'>
                    <ImageCol src={'women_owned.png'} alt={'women owned'} />
                </div>
            </div>
            <div id='contact-us'>
                <ContactUsView
                    isMessageRequired={true}
                    trackingCategory={TrackingCategories.About}
                />
            </div>
        </div>
    );

};

export default About;
